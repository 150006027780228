<template>
  <v-pagination
    class="paginationBody text-center pt-6"
    :class="{ 'simple-pagination': dataPagination.simple }"
    v-if="dataPagination.lastPage > 1"
    color="paginationBg"
    :value="dataPagination.page"
    :length="dataPagination.lastPage"
    :page="dataPagination.page"
    :total-visible="8"
    :disabled="loading"
    prev-icon="$prevIcon"
    next-icon="$nextIcon"
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
export default {
  name: 'TablePagination',
  inheritAttrs: false,
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    dataPagination: {
      type: Object,
      default: () => ({
        page: 1,
        lastPage: 1,
        simple: false
      })
    },
  },
  computed: {
    totalVisible() {
      return this.dataPagination.simple ? 1 : 8
    },
  },
}
</script>