<template>
  <v-hover #default="{ hover }">
    <v-select
      class="select-input-field font-normal on-page-select"
      :class="{ 'active-search': focusSelect || hover }"
      v-bind="$attrs"
      v-on="$listeners"
      v-model="onPage"
      :items="items"
      flat
      solo
      background-color="transparent"
      append-icon="mdi-chevron-down"
      :menu-props="{ contentClass: 'form-select-menu' }"
      hide-details
      prefix="On page"
      :height="height"
      @focus="changeFocus(true)"
      @blur="changeFocus(false)"
    >
      <template #item="{ item }">
        <span class="mx-auto">{{ item }}</span>
      </template>
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>
    </v-select>
  </v-hover>
</template>

<script>
import { getOnPage, setOnPage } from '@/helper/app-helper'

export default {
  name: 'SelectOnPage',
  inheritAttrs: false,
  props: {
    items: {
      type: Array,
      default: () => [10, 25, 50]
    },
    checkBg: {
      type: Boolean,
      default: false
    },
    height: {
      type: Number | String,
      default: 46
    }
  },
  data: () => ({
    focusSelect: false,
  }),
  computed: {
    onPage: {
      get() {
        return getOnPage()
      },
      set(onPage) {
        if (onPage !== getOnPage()) {
          setOnPage(onPage)
          this.$emit('changeOnPage')
        }
      }
    },
  },
  methods: {
    changeFocus(val) {
      this.focusSelect = val
    }
  }
}
</script>