<template>
  <v-container fluid class="pa-8 pa-sm-15">
    <div class="d-flex flex-wrap align-center justify-end">
      <portal to="pageTitle" :disabled="checkMd">
        <div class="font-page-header pr-5 pr-sm-15 mb-md-15">Manage numbers</div>
      </portal>
      
      <ActionOutlinedButton :to="{ name: 'CreateNumber'}" class="mr-5 mr-md-11 mb-4 mb-sm-15">
        Buy number
      </ActionOutlinedButton>
      
      <RefreshButton class="mr-5 mr-md-11 mb-4 mb-sm-15" :disabled="loading" @click="refresh" />
  
      <TooltipAction bottom message="Attach to pool" v-if="isManager">
        <template #activator>
          <IconButton
            class="mr-5 mr-md-11 mb-4 mb-sm-15"
            :disabled="loading || !selected.length"
            icon="linkBothIcon"
            @click="openDialogAttachNumbers"
          />
        </template>
      </TooltipAction>
      
      <v-spacer />
      
      <SearchField
        v-model="search"
        class="mb-4 mb-sm-15"
        @changedSearch="refresh"
      />
  
      <SelectOnPage
        class="pagination-container paginationColor--text ml-5 ml-sm-8 mb-4 mb-sm-15"
        :readonly="loading"
        @changeOnPage="refresh"
      />
      
      <MenuNumberFilter
        @change="setNumberFilter"
      />
    </div>
    
    <div class="number-container">
      <v-data-table
        v-model="selected"
        :headers="headers"
        :items="items"
        :loading="loading"
        :loader-height="2"
        :options="options"
        :custom-sort="() => items"
        :show-select="isManager"
        class="elevation-0 borderNone tableBg rounded-20 borderTable"
        outlined
        disable-pagination
        hide-default-footer
        :header-props="{ sortIcon: '$sortDownIcon' }"
        :mobile-breakpoint="0"
        @update:options="changeOptions"
      >
        <template #no-data>
          <div class="d-flex">
            <span class="font-normal text-left">No data available</span>
          </div>
        </template>
        
        <template #header.data-table-select="{ on, props }">
          <PrimaryCheckbox
            v-bind="props"
            v-on="on"
            hide-details
          />
        </template>
        
        <template #item.data-table-select="{ select, isSelected }">
          <PrimaryCheckbox
            v-model="isSelected"
            @change="select"
            hide-details
          />
        </template>
        
        <template #item.number="{ item }">
          +{{ item.number }}
        </template>
        
        <template #item.name="{ item }">
          <TooltipAction
            v-if="item.name"
            :message="item.name"
            :openDelay="500"
            bottom
          >
            <template #activator>
              <div class="text-truncate maxWidth--300">
                {{ item.name }}
              </div>
            </template>
          </TooltipAction>
          <span class="font-normal" v-else>-//-</span>
        </template>
        
        <template #item.country="{ item }">
          <span class="text-no-wrap">
            {{ item.country ? item.country.name : '-//-' }}
          </span>
        </template>
        
        <template #item.campaign="{ item }">
          <span class="text-no-wrap">
            {{ item.campaign ? item.campaign.name : '-//-' }}
          </span>
        </template>
        
        <template #item.allocated="{ item }">
          <span class="text-no-wrap">
            {{ item.allocated }}
          </span>
        </template>
        
        <template #item.renew="{ item }">
          <span class="text-no-wrap">
            {{ getRenew(item['last_charge']) }}
          </span>
        </template>
        
        <template #item.poolName="{ item }">
          <span class="text-no-wrap">
            {{ item['number_pools'].length ? item['number_pools'][0].name : '-//-' }}
          </span>
        </template>
        
        <template #item.publisher="{ item }">
          {{ item.publisher ? item.publisher.name : '-//-' }}
        </template>
        
        <template #item.status_id="{ item }">
          <SwitchSm
            :value="getStatus(item)"
            @input="changeStatus(item)"
            :loading="loadingStatus"
            bgColor="switcherYellow"
          />
        </template>
        
        <template #item.actions="{ item }">
          <TooltipAction bottom message="Edit">
            <template #activator>
              <v-icon class="mr-6" @click="editNumber(item)">$pencilIcon</v-icon>
            </template>
          </TooltipAction>
          
          <TooltipAction bottom message="Delete">
            <template #activator>
              <v-icon @click="openDeleteNumberDialog(item)">$deleteIcon</v-icon>
            </template>
          </TooltipAction>
        </template>
      </v-data-table>

      <TablePagination
        :loading="loading"
        :dataPagination="dataPagination"
        @input="changePage"
      />

      <div :class="dataPagination.lastPage > 1 ? 'pb-4' : 'pb-26'"></div>
    </div>
    
    <DialogConfirm
      v-model="confirmDialog"
      :title="dialogTitle"
      :subTitle="dialogSubTitle"
      :message="dialogMessage"
      :loading="loadingAction"
      @actionSuccess="actionSuccess"
    />
    
    <DialogAttachNumbersToPool
      v-model="dialogAttachToPool"
      :numberIds="dialogNumberIds"
      @actionSuccess="changeUpdatedNumbers"
    />
  
    <DialogEditNumber
      v-model="dialogEditNumber"
      :editNumber="selectedNumber"
      @actionSuccess="changeUpdatedNumber"
    />
    
    <DialogChangeCountry
      v-model="dialogChangeCountry"
      :editNumber="selectedNumber"
      @actionSuccess="changeUpdatedNumber"
    />
    
  </v-container>
</template>

<script>
import ActionOutlinedButton from '@/components/buttons/ActionOutlinedButton'
import RefreshButton from '@/components/buttons/RefreshButton'
import SearchField from '@/components/inputs/SearchField'
import SelectOnPage from '@/components/select/SelectOnPage'
import PrimaryCheckbox from '@/components/controls/PrimaryCheckbox'
import SwitchSm from '@/components/controls/SwitchSm'
import TooltipAction from '@/components/tooltip/TooltipAction'
import DialogConfirm from '@/components/dialog/DialogConfirm'
import MenuNumberFilter from '@/components/menu/MenuNumberFilter'
import IconButton from '@/components/buttons/IconButton'
import DialogAttachNumbersToPool from '@/components/dialog/DialogAttachNumbersToPool'
import DialogEditNumber from '@/components/dialog/DialogEditNumber'
import DialogChangeCountry from '@/components/dialog/DialogChangeCountry'
import TablePagination from '@/components/pagination/TablePagination'

import { getNumbers, updateNumber, deleteNumber, getNumbersForVendor, updateNumberForVendor, deleteNumberForVendor } from '@/api/number-routes'
import { getOnPage, changeItemsAfterUpdate, renewDate, parsePaginationData } from '@/helper/app-helper'

import { mapGetters } from 'vuex'
import { Types as authTypes } from '@/store/modules/auth'

export default {
  name: 'ManageNumbers',
  components: { ActionOutlinedButton, RefreshButton, SearchField, SelectOnPage, PrimaryCheckbox, SwitchSm, TooltipAction, DialogConfirm, MenuNumberFilter, IconButton, DialogAttachNumbersToPool, DialogEditNumber, DialogChangeCountry, TablePagination },
  data: () => ({
    loading: false,
    loadingAction: false,
    loadingStatus: false,
    filterValue: null,
    selected: [],
    items: [],
    dataPagination: {
      page: 1,
      lastPage: 1,
    },
    options: {},
    headersForManager: [
      { text: 'Number', value: 'number', width: 160, sortId: 'byNumber' },
      { text: 'Name', value: 'name', sortId: 'byName' },
      { text: 'Country', value: 'country', sortId: 'byCountry' },
      { text: 'Region', value: 'region', sortId: 'byRegion' },
      { text: 'Campaign', value: 'campaign', sortId: 'byCampaign' },
      { text: 'Allocated', value: 'allocated', sortId: 'byAllocated' },
      { text: 'Renew', value: 'renew', sortable: false },
      { text: 'Pool name', value: 'poolName', sortId: 'byPoolName' },
      { text: 'Vendor', value: 'publisher', sortId: 'byVendor' },
      { text: 'Status', value: 'status_id', align: 'center', sortId: 'byStatus' },
      { text: 'Actions', value: 'actions', sortable: false, width: 100, align: 'right' },
    ],
    headersForVendor: [
      { text: 'Number', value: 'number', width: 160, sortId: 'byNumber' },
      { text: 'Name', value: 'name', sortId: 'byName' },
      { text: 'Country', value: 'country', sortId: 'byCountry' },
      { text: 'Region', value: 'region', sortId: 'byRegion' },
      { text: 'Allocated', value: 'allocated', sortId: 'byAllocated' },
      { text: 'Renew', value: 'renew', sortable: false },
      { text: 'Status', value: 'status_id', align: 'center', sortId: 'byStatus' },
      { text: 'Actions', value: 'actions', sortable: false, width: 100, align: 'right' },
    ],
    dialogNumberId: null,
    dialogNumberIds: [],
    dialogAttachToPool: false,
    dialogEditNumber: false,
    dialogChangeCountry: false,
    selectedNumber: null,
    confirmDialog: false,
    selectedItem: null,
    actionName: null,
    dialogTitle: null,
    dialogSubTitle: null,
    dialogMessage: null,
    serverSorting: null,
    search: null,
  }),
  computed: {
    ...mapGetters({
      isManager: authTypes.getters.IS_MANAGER,
    }),
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    headers() {
      return this.isManager ? this.headersForManager : this.headersForVendor
    }
  },
  methods: {
    changeOptions(options) {
      if (!this.loading) {
        this.options = Object.assign({}, options)
      }
    },
    async loadNumbers() {
      this.selected = []
      this.loading = true
      const params = {
        page: this.dataPagination.page,
        onPage: getOnPage(),
        search: this.search,
        filter: this.filterValue,
        ...this.serverSorting,
      }
      
      const { success, payload, message } = this.isManager
        ? await getNumbers(params)
        : await getNumbersForVendor(params)

      if (success) {
        this.dataPagination = parsePaginationData(payload)
        this.items = payload.data
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loading = false
    },
    setNumberFilter(filterValue) {
      this.dataPagination.page = 1
      this.filterValue = filterValue
      
      this.loadNumbers()
    },
    openDialogAttachNumbers() {
      if (this.selected.length) {
        this.dialogNumberIds = this.selected.map(number => number.id)
        this.dialogAttachToPool = true
      }
    },
    changeUpdatedNumbers(updatedNumbers) {
      this.dialogAttachToPool = false
      this.loadNumbers()
    },
    changeUpdatedNumber(updatedNumber) {
      this.items = changeItemsAfterUpdate(this.items, updatedNumber, 'id')
    },
    actionSuccess() {
      this[this.actionName]()
    },
    openDialogChangeCountry(number) {
      this.selectedNumber = number
      this.dialogChangeCountry = true
    },
    editNumber(number) {
      this.selectedNumber = number
      this.dialogEditNumber = true
    },
    openDeleteNumberDialog(number) {
      this.actionName = 'deleteNumber'
      this.selectedItem = number
      this.dialogTitle = 'Delete number'
      this.dialogSubTitle = `${number.number}`
      this.dialogMessage = 'Are you sure you want to delete this phone number?'
      this.confirmDialog = true
    },
    async deleteNumber() {
      this.loadingAction = true

      const { success, message } = this.isManager
        ? await deleteNumber(this.selectedItem.id)
        : await deleteNumberForVendor(this.selectedItem.id)

      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.confirmDialog = false
        this.refreshDataAfterDelete()
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }

      this.loadingAction = false
    },
    refreshDataAfterDelete() {
      if (this.items.length === 1 && this.dataPagination.page !== 1) {
        --this.dataPagination.page
      }

      this.loadNumbers()
    },
    getStatus(item) {
      return item['status_id'] === 1
    },
    async changeStatus(number) {
      this.loadingStatus = true
      
      const { success, payload, message } = this.isManager
        ? await updateNumber({ numberId: number.id, formData: { 'toggle_status': true } })
        : await updateNumberForVendor({ numberId: number.id, formData: { 'toggle_status': true } })
      
      if (success) {
        this.$notify({ type: 'success-bg', text: message })
        this.changeUpdatedNumber(payload)
      } else {
        !!message && this.$notify({ type: 'error-bg', duration: 15000, text: message })
      }
      
      this.loadingStatus = false
    },
    changePage(page) {
      if (page !== this.dataPagination.page) {
        this.dataPagination.page = page
        this.loadNumbers()
      }
      this.$vuetify.goTo(0)
    },
    refresh() {
      this.dataPagination.page = 1
      this.loadNumbers()
    },
    getRenew(lastCharge) {
      return renewDate(lastCharge)
    },
  },
  watch: {
    options: {
      deep: true,
      handler(sortOptions) {
        if (sortOptions.sortBy.length) {
          const sortBy = this.headers.find(item => item.value === sortOptions.sortBy[0])
          this.serverSorting = { [sortBy.sortId]: sortOptions.sortDesc[0] ? 'asc' : 'desc' }
        } else {
          this.serverSorting = null
        }
        this.loadNumbers()
      },
    },
  },
}
</script>
