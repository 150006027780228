<template>
  <v-hover #default="{ hover }">
    <v-btn
      v-bind="$attrs"
      v-on="$listeners"
      text
      outlined
      class="rounded refresh-btn"
      :height="height"
      :min-width="minWidth"
      :width="width"
      :ripple="false"
    >
      <v-icon :color="hover ? 'inputColorHover' : 'inputColor'">$refreshIcon</v-icon>
    </v-btn>
  </v-hover>
</template>

<script>
export default {
  name: 'RefreshButton',
  inheritAttrs: false,
  props: {
    height: {
      type: Number | String,
      default: 48
    },
    minWidth: {
      type: Number | String,
      default: 54
    },
    width: {
      type: Number | String,
      default: 54
    }
  }
}
</script>

<style lang="scss">

</style>
